<template id="point-comparison-select-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ i18n.$t('tools.analyse-skeleton.choose-labels') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.select') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item-divider>
          <ion-label>
            {{ i18n.$t('tools.analyse-skeleton.general_settings') }}
          </ion-label>
        </ion-item-divider>
        <ion-list-header>
          <ion-label>{{ i18n.$t('tools.analyse-skeleton.scaling') }}: <i>{{ scalingPercentage }} %</i></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-range min="100" max="400" step="1" pin="true" snaps="true" ticks="false" v-model="scalingPercentage">
            <ion-label slot="start">100 %</ion-label>
            <ion-label slot="end">{{ scalingPercentage }} % | 400 %</ion-label>
          </ion-range>
        </ion-item>
        <ion-list-header>
          <ion-label>{{ i18n.$t('tools.analyse-skeleton.threshold') }}: <i>{{ thresholdPercentage }} %</i></ion-label>
        </ion-list-header>
        <ion-item>
          <ion-range min="0" max="100" step="1" pin="true" snaps="true" ticks="false" v-model="thresholdPercentage">
            <ion-label slot="start">0 %</ion-label>
            <ion-label slot="end">{{ thresholdPercentage }} % | 100 %</ion-label>
          </ion-range>
        </ion-item>
        <ion-list-header>
          <ion-label>{{ i18n.$t('tools.analyse-skeleton.reference') }}</ion-label>
        </ion-list-header>
        <ion-item class="comparison-item reference-item" lines="full">
          <ion-select 
            :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
            :cancelText="i18n.$t('default_interaction.cancel')"
            :okText="i18n.$t('default_interaction.select')"
            v-model="leftReference"
            :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
            <!-- Fallback option for preselection without loaded data -->
            <ion-select-option v-if="leftReference != null && !(options.includes(leftReference))" :value="leftReference">{{ leftReference }}</ion-select-option>
            <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
          </ion-select>
          <span>&#8644;</span>
          <ion-select 
            :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
            :cancelText="i18n.$t('default_interaction.cancel')"
            :okText="i18n.$t('default_interaction.select')"
            v-model="rightReference"
            :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
            <!-- Fallback option for preselection without loaded data -->
            <ion-select-option v-if="rightReference != null && !(options.includes(rightReference))" :value="rightReference">{{ rightReference }}</ion-select-option>
            <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
          </ion-select>
          <ion-button slot="end" class="delete-button" fill="none" @click="removeReference">
            <ion-icon slot="icon-only" :icon="trash" color="danger"></ion-icon>
          </ion-button>
        </ion-item>
        <!-- Comparison of two points each -->
        <ion-item-divider>
          <ion-label>
            {{ i18n.$t('tools.analyse-skeleton.comparisons') }}
          </ion-label>
          <div slot="end" class="divider-toggles">
            <ion-icon :icon="eye"></ion-icon>
            <ion-toggle :checked="newEnableSettings.comparison" @ionChange="setEnableSetting('comparison', $event.target.checked)"></ion-toggle>
          </div>
        </ion-item-divider>
        <ion-item class="comparison-item" v-for="(selection, index) in newSelection" :key="index" lines="full">
          <ion-select 
            :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
            :cancelText="i18n.$t('default_interaction.cancel')"
            :okText="i18n.$t('default_interaction.select')"
            :value="selection[0]"
            @ionChange="selection[0] = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null"
            :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
            <!-- Fallback option for preselection without loaded data -->
            <ion-select-option v-if="selection[0] != null && !(options.includes(selection[0]))" :value="selection[0]">{{ selection[0] }}</ion-select-option>
            <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
          </ion-select>
          <span>&#8644;</span>
          <ion-select 
            :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
            :cancelText="i18n.$t('default_interaction.cancel')"
            :okText="i18n.$t('default_interaction.select')"
            :value="selection[1]"
            @ionChange="selection[1] = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null"
            :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
            <!-- Fallback option for preselection without loaded data -->
            <ion-select-option v-if="selection[1] != null && !(options.includes(selection[1]))" :value="selection[1]">{{ selection[1] }}</ion-select-option>
            <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
          </ion-select>
          <ion-button slot="end" class="delete-button" fill="none" @click="removeComparisonSelection(index)">
            <ion-icon slot="icon-only" :icon="trash" color="danger"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item button color="success" detail="false" class="add-button" @click="addComparisonSelection">
          <ion-label position="fixed"><b>{{ i18n.$t('tools.analyse-skeleton.add_comparison') }}</b></ion-label>
          <ion-icon slot="end" :icon="add"></ion-icon>
        </ion-item>
        <!-- Single Coordinates -->
        <ion-item-divider>
          <ion-label>
            {{ i18n.$t('tools.analyse-skeleton.coordinates') }}
          </ion-label>
          <div slot="end" class="divider-toggles">
            <ion-icon :icon="eye"></ion-icon>
            <span>X</span>
            <ion-toggle :checked="newEnableSettings.x" @ionChange="setEnableSetting('x', $event.target.checked)"></ion-toggle>
            <ion-icon :icon="eye"></ion-icon>
            <span>Y</span>
            <ion-toggle :checked="newEnableSettings.y" @ionChange="setEnableSetting('y', $event.target.checked)"></ion-toggle>
          </div>
        </ion-item-divider>
        <ion-item class="coordinate-item" v-for="(coordinate, index) in newCoordinates" :key="index" lines="full">
          <div class="coordinate-container">
            <ion-item class="select-wrapper">
              <ion-select 
                :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
                :cancelText="i18n.$t('default_interaction.cancel')"
                :okText="i18n.$t('default_interaction.select')"
                :value="coordinate.name"
                @ionChange="coordinate.name = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null"
                :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
                <!-- Fallback option for preselection without loaded data -->
                <ion-select-option v-if="coordinate.name != null && !(options.includes(coordinate.name))" :value="coordinate.name">{{ coordinate.name }}</ion-select-option>
                <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
              </ion-select>
            </ion-item>
            <div class="coordinate-options">
              <span>
                <ion-item class="checkbox-wrapper" lines="none">
                  <ion-checkbox :checked="coordinate.x != null" @ionChange="coordinate.x = createCoordinate($event.target.checked)"></ion-checkbox>
                  <ion-label>X</ion-label>
                </ion-item>
                <ion-item class="checkbox-wrapper" lines="none" :disabled="coordinate.x == null">
                  <ion-checkbox :checked="coordinate.x != null && coordinate.x.scaling" @ionChange="(coordinate.x != null) ? coordinate.x.scaling = $event.target.checked : true"></ion-checkbox>
                  <ion-label>{{ i18n.$t('tools.analyse-skeleton.scale') }}</ion-label>
                </ion-item>
              </span>
              <span>
                <ion-item class="checkbox-wrapper" lines="none">
                  <ion-checkbox :checked="coordinate.y != null" @ionChange="coordinate.y = createCoordinate($event.target.checked)"></ion-checkbox>
                  <ion-label>Y</ion-label>
                </ion-item>
                <ion-item class="checkbox-wrapper" lines="none" :disabled="coordinate.y == null">
                  <ion-checkbox :checked="coordinate.y != null && coordinate.y.scaling" @ionChange="(coordinate.y != null) ? coordinate.y.scaling = $event.target.checked : true"></ion-checkbox>
                  <ion-label>{{ i18n.$t('tools.analyse-skeleton.scale') }}</ion-label>
                </ion-item>
              </span>
            </div>
            <ion-item class="select-wrapper" lines="none">
              <span slot="start">{{ i18n.$t('tools.analyse-skeleton.relative_to') }}:</span>
              <ion-select 
                :placeholder="i18n.$t('tools.analyse-skeleton.choose-label')"
                :cancelText="i18n.$t('default_interaction.cancel')"
                :okText="i18n.$t('default_interaction.select')"
                :value="coordinate.relativeTo"
                @ionChange="coordinate.relativeTo = ($event.target.value != null && $event.target.value.length) ? $event.target.value : null"
                :interface-options="{cssClass: 'select-entry-item', header: i18n.$t('tools.analyse-skeleton.choose-label')}">
                <!-- Fallback option for preselection without loaded data -->
                <ion-select-option v-if="coordinate.relativeTo != null && !(options.includes(coordinate.relativeTo))" :value="coordinate.relativeTo">{{ coordinate.relativeTo }}</ion-select-option>
                <ion-select-option v-for="option in options" :key="option" :value="option">{{ option }}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <ion-button slot="end" class="delete-button" fill="none" @click="removeCoordinate(index)">
            <ion-icon slot="icon-only" :icon="trash" color="danger"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item button color="success" detail="false" class="add-button" @click="addCoordinate">
          <ion-label position="fixed"><b>{{ i18n.$t('tools.analyse-skeleton.add_coordinate') }}</b></ion-label>
          <ion-icon slot="end" :icon="add"></ion-icon>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonListHeader, IonLabel, IonSelect, IonSelectOption, IonIcon, IonRange, IonItemDivider, IonCheckbox, IonToggle, modalController } from '@ionic/vue';
import { computed, defineComponent, ref } from 'vue';

import { 
  add,
  trash,
  eye
} from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import _ from 'lodash';

const PointComparisonSelectModal = defineComponent({
  name: 'PointComparisonSelectModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonListHeader, IonLabel, IonSelect, IonSelectOption, IonIcon, IonRange, IonItemDivider, IonCheckbox, IonToggle },
  props: {
    'scaling': Number,
    'reference': Array,
    'selection': Array,
    'options': Array,
    'threshold': Number,
    'coordinates': Array,
    'enableSettings': Object
  },
  setup(props) {
    const i18n = useI18n();

    const newReference = ref(props.reference);
    const newSelection = ref(props.selection);
    const newThreshold = ref(props.threshold);
    const newScaling = ref(props.scaling);
    const newCoordinates = ref(props.coordinates);
    const newEnableSettings = ref(props.enableSettings);

    const thresholdPercentage = computed({
      get: function(){
        return Math.round(newThreshold.value * 100);
      },
      set: function(newValue){
        newThreshold.value = newValue / 100;
      }
    });

    const scalingPercentage = computed({
      get: function(){
        return Math.round(newScaling.value * 100);
      },
      set: function(newValue){
        newScaling.value = newValue / 100;
      }
    });

    const closeModal = function(){
      modalController.dismiss({scaling: newScaling.value, reference: newReference.value, selection: newSelection.value, threshold: newThreshold.value, coordinates: newCoordinates.value, enableSettings: newEnableSettings.value});
    }

    const addComparisonSelection = function(){
      newSelection.value.push([null, null]);
    }

    const removeReference = function(){
      newReference.value = [];
    }

    const removeComparisonSelection = function(index){
      _.pullAt(newSelection.value, index);
    }

    const addCoordinate = function(){
      newCoordinates.value.push({name: null, });
    }

    const removeCoordinate = function(index){
      _.pullAt(newCoordinates.value, index);
    }

    const setEnableSetting = function(setting, value) {
      newEnableSettings.value[setting] = value;
    }

    const createCoordinate = function(enabled) {
      if (enabled) {
        return {};
      } else {
        return undefined;
      }
    }

    const leftReference = computed({
      get: function(){
        return newReference.value[0];
      },
      set: function(newValue){
        if (newValue === '') {
          newReference.value[0] = null;
        } else {
          newReference.value[0] = newValue;
        }
      }
    });

    const rightReference = computed({
      get: function(){
        return newReference.value[1];
      },
      set: function(newValue){
        if (newValue === '') {
          newReference.value[1] = null;
        } else {
          newReference.value[1] = newValue;
        }
      }
    });

    return { i18n, closeModal, add, trash, eye, addComparisonSelection, removeReference, removeComparisonSelection, addCoordinate, removeCoordinate, setEnableSetting, createCoordinate, leftReference, rightReference, newReference, newSelection, thresholdPercentage, scalingPercentage, newCoordinates, newEnableSettings };
  }
});

export async function openPointComparisonSelectModal(component, scaling, reference, selection, options, threshold, coordinates, enableSettings){
  if (component != null && scaling != null && reference && selection && options && threshold != null && Array.isArray(reference) && Array.isArray(selection) && Array.isArray(options) && coordinates && Array.isArray(coordinates) && enableSettings) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          scaling,
          reference: _.cloneDeep(reference),
          selection: _.cloneDeep(selection),
          options: _.cloneDeep(options),
          threshold,
          coordinates: _.cloneDeep(coordinates),
          enableSettings: _.cloneDeep(enableSettings)
        },
      })
    modal.present();
    return modal.onDidDismiss();
  }
}

export default PointComparisonSelectModal;
</script>


<style>
/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.select-entry-item .alert-radio-label, .select-entry-item .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  text-indent: -6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.select-entry-item .select-interface-option {
  height: auto;
  contain: content;
}
</style>

<style scoped>
.add-button ion-label {
  text-align: start!important;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
}

.add-button {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.comparison-item ion-select {
  flex-grow: 1;
  width: 45%;
}

.comparison-item span {
  margin-left: 20px;
  margin-right: 20px;
}

.comparison-item, .coordinate-item {
  --inner-padding-end: 0px;
}

.coordinate-item {
  --padding-start: 10px;
}

.delete-button {
  margin: 0px;
  margin-left: 10px;
  height: 100%;
  --padding-start: 10px;
  --padding-end: 10px;
}

.divider-toggles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: var(--ion-text-color, #000);
}

.divider-toggles :not(:last-child) {
  margin-right: 2px;
}

.divider-toggles ion-toggle:not(:last-of-type) {
  margin-right: 10px;
}

.divider-toggles ion-toggle {
  padding-left: 5px;
  padding-right: 5px;
}

ion-list-header.ios {
  font-size: inherit;
}

.coordinate-container {
  flex-grow: 1;
}

.coordinate-container > ion-select {
  margin-left: 20px;
}

.coordinate-options, .coordinate-options > span {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.coordinate-options {
  --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  border-bottom: 1px solid var(--border-color);
}

.coordinate-options > span:first-of-type {
  border-right: 1px solid var(--border-color);
  padding-right: 5px;
}

.coordinate-options > span:last-of-type {
  padding-right: 5px;
}

.checkbox-wrapper {
  flex-shrink: 1;
  --padding-start: 2px;
  --padding-end: 0px;
  --inner-padding-end: 2px;
}

.checkbox-wrapper > ion-label {
  margin-left: 5px;
}

.checkbox-wrapper:not(.ios) {
  padding-left: 10px;
}

ion-item.select-wrapper {
  margin-left: 0px;
}

.select-wrapper ion-select {
  max-width: unset;
}
</style>